import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  token: string | null;

  constructor(private http: HttpClient) { 
    const userLocal = localStorage.getItem('userPlataformaTY');
    if (userLocal) {
      this.token = JSON.parse(userLocal).token;
    } else {
      this.token = "No exite token";
    }
  }

  esClienteTEROS(nitci:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/teros/cliente/documento/${nitci}`;
    return this.http.get<any>(url, httpOptions)
  }

  gettipoDocumento() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/teros/cliente/tipo-documento`;
    return this.http.get<any>(url, httpOptions)
  }

  tipoClientes() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/teros/cliente/tipo`;
    return this.http.get<any>(url, httpOptions)
  }

  ciudadClientes() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/ciudad`;
    return this.http.get<any>(url, httpOptions)
  }

  login(datos: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/login`;
    return this.http.post<any>(url, datos, httpOptions);
  }

  registrarenEcommerce(datos: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/usuario`;
    return this.http.post<any>(url, datos, httpOptions);
  }

  registrarenTeros(datos: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/usuario/perfil`;
    return this.http.post<any>(url, datos, httpOptions);
  }

  edicionDatosUsuario(datos: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/usuario/perfil`;
    return this.http.post<any>(url, datos, httpOptions);
  }

  cotizador(respuestas: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/cotizacion/vehiculo`;
    return this.http.post<any>(url, respuestas, httpOptions);
  }

  requestPin(phone: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/login/telefono/${phone}`;
    return this.http.get<any>(url, httpOptions);
  }

  get windowRef(){
    return window;
  }

  doResetPassword(data: any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/login/reset-password`;
    return this.http.post<any>(url, data, httpOptions);
  }

  eliminarcuenta(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/usuario`;
    return this.http.delete<any>(url, httpOptions);
  }

}
